import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../shared/services/login.service';
import { Router } from '@angular/router';
import { IPage } from '../../shared/models/page/page.model';
import { TranslateService } from '@ngx-translate/core';
import { IEmployee } from '../../shared/models/employee.model';

@Component({
  selector: 'app-nav-links',
  templateUrl: './nav-links.component.html',
  styleUrls: ['./nav-links.component.scss'],
})
export class NavLinksComponent implements OnInit {
  @ViewChild('navToggler') navToggler: ElementRef;
  @Input() inPanel: boolean;
  @Input() allPages: IPage[];

  user: IEmployee;
  isUserRoleUser: boolean;
  isUserRoleAdmin: boolean;
  isUserRoleLead: boolean;
  isUserRoleOperationsAdmin: boolean;
  isUserRoleSuperAdmin: boolean;

  constructor(
    public loginService: LoginService,
    public router: Router,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    this.user = this.loginService.getUser();
    this.isUserRoleUser = this.loginService.isUserRoleUser();
    this.isUserRoleAdmin = this.loginService.isUserRoleAdmin();
    this.isUserRoleLead = this.loginService.isUserRoleLead();
    this.isUserRoleOperationsAdmin = this.loginService.isUserRoleOperationsAdmin();
    this.isUserRoleSuperAdmin = this.loginService.isUserRoleSuperAdmin();
  }

  public hasVisibleChildren(page: IPage): boolean {
    if (!page.children.length) {
      return false;
    }

    for (const child of page.children) {
      if (child.visible) {
        return true;
      }
    }

    return false;
  }

  public logout(): void {
    this.loginService.logOut();
  }
}
