import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from '../shared/services';
import { Event, Router } from '@angular/router';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { PageService } from '../shared/services/page.service';
import { TranslateService } from '@ngx-translate/core';
import { IEmployee } from '../shared/models';
import { IPage } from '../shared/models/page/page.model';
import { EmployeeService } from '../shared/services';


@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit, OnDestroy {

  user: IEmployee;
  userId: number;
  avatarSrc: string;
  isUserRoleDevops: boolean;
  allPages: IPage[];

  private destroy$ = new Subject<void>();
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  public navExpanded = false;
  public searchExpanded = false;

  constructor(
    public loginService: LoginService,
    public pageService: PageService,
    public router: Router,
    public translate: TranslateService,
    public employeeService: EmployeeService,
  ) {
    this.user = this.loginService.getUser();
    this.userId = this.loginService.getUserId();
    this.isUserRoleDevops = this.loginService.isUserRoleDevops();
  }

  ngOnInit(): void {
    this.loginService
      .getAvatarUrlToShow()
      .pipe(takeUntil(this.destroy$))
      .subscribe((url) => {
        this.avatarSrc = url;
      });

    this.pageService
      .getAllPages()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.allPages = response;
      });
  }

  public toggleExpand(): void {
    this.navExpanded = !this.navExpanded;
    this.searchExpanded = false;
  }

  public toggleHeaderExpand(): void {
    this.searchExpanded = !this.searchExpanded;
    this.navExpanded = false;
  }

  public isUserRoleAdmin() {
    return this.loginService.isUserRoleAdmin();
  }

  public logout(): void {
    this.loginService.logOut();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
