<div class="general-search align-center">
  <mat-form-field class="calendar-search-field" appearance="fill">
    <mat-icon matPrefix>search</mat-icon>
    <input
      id="search-term"
      type="search"
      placeholder="Search for anything"
      (keydown)="$event.stopPropagation()"
      (keyup.enter)="search(filter)"
      [(ngModel)]="filter"
      autocomplete="off"
      matInput
    />
  </mat-form-field>
</div>
