<ul>
  <li *ngFor="let emp of data">
    <img
      class="avatar"
      [src]="emp.image | base64ToImage: '/assets/img/defaultAvatar.png'"
      [alt]="emp.employeeName"
    />
    <div class="main">
      <p *ngIf="emp.eventType === 'DREAMIX_BIRTHDAY'">
        <strong>{{ emp.employeeName }}</strong> celebrates
        <strong>{{ emp.years }} years with</strong>&nbsp;
        <strong class="dreamix">DREAMIX</strong>
      </p>
      <p *ngIf="emp.eventType === 'EMPLOYEE_BIRTHDAY'">
        <strong>Happy birthday, {{ emp.employeeName }}!</strong>
      </p>
      <p *ngIf="emp.eventType === 'POSITION_CHANGED'">
        <strong>{{ emp.employeeName }}</strong> has been promoted to
        <strong
          >{{ emp.newPosition }}! Congratulations, {{ emp.employeeName.split(' ')[0] }}!</strong
        >
      </p>
      <p *ngIf="emp.eventType === 'NEW_EMPLOYEE'">
        Say <strong>Hi</strong> to our new team member <strong>{{ emp.employeeName }}</strong
        >.<br /><strong>Welcome to</strong>&nbsp;<strong class="dreamix">DREAMIX</strong>!
      </p>
      <p *ngIf="emp.eventType === 'CERTIFICATION_ACHIEVED'">
        <strong>{{ emp.employeeName }}</strong> has obtained
        <strong>{{ emp.oldPosition }}</strong> Certification.
      </p>
      <p class="date">{{ emp.date.replaceAll('-', '/').replaceAll('.', '/') }}</p>
    </div>

    <img
      class="avatar"
      [src]="'/assets/icons/wall-of-fame/' + emp.eventType + '.svg'"
      [alt]="emp.eventType"
    />
  </li>
</ul>
