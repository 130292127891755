import { Component, OnDestroy } from '@angular/core';
import { RequestService } from '../shared/services/request.service';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { IEmployee } from '../shared/models/employee.model';

@Component({
  selector: 'app-action-request',
  templateUrl: './action-request.component.html',
  styleUrls: ['./action-request.component.scss'],
})
export class ActionRequestComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  public approve: boolean;
  public user: IEmployee;

  public ready: boolean = false;

  constructor(
    private requestService: RequestService,
    private route: ActivatedRoute,
    public translate: TranslateService,
  ) {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.approve = params.action === 'true';
      // todo FIXME this is bad. subscribe method is called inside subscribe. Use switchMap instead.
      this.requestService.actionRequest(params.id, this.approve, params.hash).subscribe(() => {
        if (this.approve) {
          const isReady = new Subject<boolean>();
          isReady.subscribe(() => {
            this.ready = true;
          });
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
