<header class="dms-header" xmlns="http://www.w3.org/1999/html">
  <mat-expansion-panel hideToggle [expanded]="navExpanded || searchExpanded">
    <mat-expansion-panel-header>
      <nav
        class="navbar"
        [ngClass]="navExpanded || searchExpanded ? 'navbar-expanded' : ''"
        (click)="$event.stopPropagation()"
      >
        <div class="main-layout navbar-content">
          <a class="navbar-brand" routerLink="/">
            <img src="../../assets/img/logo.png" alt="Dreamix Logo" />
          </a>

          <div class="navbar-collapse align-center avatar-wrapper">
            <div class="change-order">
              <div class="search-container">
                <div [ngClass]="{ 'icons-holder': searchExpanded }">
                  <button
                    class="navbar-toggler"
                    (click)="toggleHeaderExpand()"
                    type="button"
                    data-toggle="collapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <mat-icon>search</mat-icon>
                  </button>
                </div>
              </div>
              <app-search id="search" *ngIf="!isUserRoleDevops"></app-search>
              <ul class="align-center nav-links">
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown"
                    id="user"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    [matMenuTriggerFor]="profileMenu"
                    [class.active]="router.isActive(router.parseUrl('/user'), false)"
                  >
                    {{ this.user.firstNameEn }}
                  </a>
                  <mat-menu #profileMenu="matMenu">
                    <app-user-nav-links></app-user-nav-links>
                  </mat-menu>
                </li>
              </ul>
              <img
                class="avatar"
                [src]="avatarSrc"
                alt="{{ user.firstNameEn + ' ' + user.lastNameEn }}"
              />
            </div>

            <div class="navbar-collapse align-center" id="navbarNavDropdown">
              <app-nav-links class="full-height" [allPages]="allPages"></app-nav-links>
              <div [ngClass]="{ 'icons-holder': navExpanded }">
                <button
                  class="navbar-toggler"
                  (click)="toggleExpand()"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <mat-icon>menu</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </mat-expansion-panel-header>
    <div>
      <div *ngIf="searchExpanded" class="search-input-holder">
        <app-search *ngIf="!isUserRoleDevops"></app-search>
      </div>
      <div *ngIf="navExpanded" class="navbar-links-holder">
        <app-nav-links class="expanded" [allPages]="allPages" [inPanel]="true"></app-nav-links>
      </div>
    </div>
  </mat-expansion-panel>
  <div class="main-layout dms-subheader breadcrumb-wrapper">
    <app-breadcrumb></app-breadcrumb>
  </div>
</header>
