import { Component, Input, OnInit } from '@angular/core';
import { LoginService } from '../../shared/services';
import { TranslateService } from '@ngx-translate/core';
import { IEmployee } from 'src/app/shared/models';

@Component({
  selector: 'app-user-nav-links',
  templateUrl: './user-nav-links.component.html',
  styleUrls: ['./user-nav-links.component.scss'],
})
export class UserNavLinksComponent implements OnInit {
  @Input() user: IEmployee;

  isUserRoleUser: boolean;
  isUserOperationsAdminUser: boolean;
  isExclusiveUser: boolean;

  constructor(
    public loginService: LoginService,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    this.isUserRoleUser = this.loginService.isUserRoleUser();
    this.isExclusiveUser = this.loginService.isExclusiveUser();
    this.isUserOperationsAdminUser = this.loginService.isUserRoleOperationsAdmin();
  }
}
