import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ObservablesService } from '../../shared/services/observables.service';
import { MenuItem } from '../../shared/models/menuItem.model';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  static readonly ROUTE_DATA_BREADCRUMB: string = 'breadcrumb';
  static readonly ROUTE_DATA_HAS_LINK: string = 'link';
  menuItems: MenuItem[];
  dynMenuItems: MenuItem[];
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private observables: ObservablesService,
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe(
      () => (this.menuItems = this.createBreadcrumbs(this.activatedRoute.root)),
    );
    this.observables.dynamicBreadcrumbs
      .pipe(takeUntil(this.destroy$))
      .subscribe((e: MenuItem[]) => (this.dynMenuItems = e));
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = '#',
    breadcrumbs: MenuItem[] = [],
  ): MenuItem[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');

      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB];
      if (label && child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_HAS_LINK]) {
        breadcrumbs.push({ label, url });
      } else if (label) {
        breadcrumbs.push({ label, url: null });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
