import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError, Subject, takeUntil, tap, throwError } from 'rxjs';
import {AlertService, AlertType, EmployeeService, LoginService} from '../shared/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  requestId: number;
  isDennyActive: boolean;
  isUserLogIn: boolean;
  isApproveActive: boolean;
  private destroy$ = new Subject<void>();

  formLogin: UntypedFormGroup;
  formChangePassword: UntypedFormGroup;
  showPassword = false;
  showPasswordLogin = false;
  showPasswordLoginLink = true;
  showChangePassword = false;
  showChangePasswordLink = false;

  constructor(
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    public loginService: LoginService,
    public employeeService: EmployeeService,
    public translate: TranslateService,
  ) {
    this.isUserLogIn = this.loginService.isUserLogIn();
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.requestId = params.id;
      this.isDennyActive = params.id > 0 && params.decline === 'true';
      this.isApproveActive = params.id > 0 && params.approve === 'true';
    });
  }

  ngOnInit(): void {
    this.loginService.subscribeAuthState();
  }

  showChangePasswordForm() :void {
    this.showChangePassword = true;
    this.showChangePasswordLink = true;
    this.showPasswordLogin = false;
    this.showPasswordLoginLink = false;
  }
  showLoginForm() :void {
    this.showChangePassword = false;
    this.showChangePasswordLink = true;
    this.showPasswordLogin = true;
    this.showPasswordLoginLink = true;
  }

  signInWithGoogle(): void {
    this.loginService.logIn();
  }

  refreshToken(): void {
    this.loginService.refreshAuthToken();
  }

  onPasswordLogin() {
    this.showLoginForm();
    this.formLogin = this.formBuilder.group({
      email: [''],
      password: [''],
    });
  }

  onChangePassword() {
    this.showChangePasswordForm();
    this.formChangePassword = this.formBuilder.group({
      email: ['']
    });
  }

  submitPasswordLogin() {
    this.loginService
      .logInWithPassword(this.formLogin.value)
      .pipe(
        tap((response) => this.loginService.handleSuccessfulLogin(response)),
        catchError((error) => {
          this.alertService.showAlert(error.error, AlertType.error);
          return throwError(error);
        }),
      )
      .subscribe();
  }

  submitChangePassword() {
    this.employeeService
      .changePassword(this.formChangePassword.value)
      .pipe(
        tap(() => {
          this.onPasswordLogin();
          this.alertService.showSuccess(this.translate.instant('LOGIN.CHANGE_PASSWORD_SUCCESS'))

        }),
        catchError((error) => {
          if(error.error) {
            this.alertService.showAlert(error.error.error, AlertType.error);
          }
          return throwError(error);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.loginService.unSubscribeAuthState();
  }
}
