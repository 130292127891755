import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from '../shared/services';
import { IEmployee } from '../shared/models';
import { WidgetsService } from '../shared/services/widgets.service';
import { MatDialog } from '@angular/material/dialog';
import { WidgetDashboardDialogComponent } from '../shared/dialogs/widget-dashboard-dialog/widget-dashboard-dialog.component';
import { DashboardWidgetData, WidgetData } from '../shared/models/widgets.model';
import { combineLatest, Subject, switchMap, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit, OnDestroy {
  user: IEmployee;
  customizeDashboardVisible: boolean;
  widgetList: Record<string, WidgetData>;
  private dashboardWidgetList: DashboardWidgetData[];
  private destroy$ = new Subject<void>();

  constructor(
    private loginService: LoginService,
    private widgetsService: WidgetsService,
    private dialog: MatDialog,
  ) {
    this.user = this.loginService.getUser();
  }

  ngOnInit(): void {
    this.widgetsService
      .getAllWidgets()
      .pipe(
        takeUntil(this.destroy$),
        tap((data) => {
          this.dashboardWidgetList = data;
          this.customizeDashboardVisible = data.some((widget) => widget.allowUserToManage);
        }),
        switchMap((data) =>
          combineLatest(
            data.reduce((res, widget) => {
              res[widget.url] = this.widgetsService.getWidget(widget.url);
              return res;
            }, {}),
          ),
        ),
      )
      .subscribe((widgets) => {
        this.widgetList = widgets;
      });
  }

  expandWidgetDashboard() {
    this.dialog.open(WidgetDashboardDialogComponent, { data: this.dashboardWidgetList });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
