import { RouterModule, Routes } from '@angular/router';

import { authGuard } from 'src/app/shared/guards/auth.guard';
import { notAuthGuard } from 'src/app/shared/guards/not-auth.guard';
import { roleGuard } from 'src/app/shared/guards/role.guard';

import { ActionRequestComponent } from './action-request/action-request.component';
import { ClientHoursComponent } from './admin/client-hours/client-hours.component';
import { EditProjectComponent } from './admin/projects/edit-project/edit-project.component';
import { ProjectsComponent } from './admin/projects/projects.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LoginComponent } from './login/login.component';
import { RequestDetailsComponent } from './request-details/request-details.component';
import { UserHelpComponent } from './user-help/user-help.component';

const routes: Routes = [
  {
    path: 'help',
    component: UserHelpComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [notAuthGuard],
  },
  {
    path: '',
    canActivateChild: [authGuard],
    children: [
      {
        path: '',
        component: LandingPageComponent,
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'dreamix',
        loadChildren: () => import('./dreamix/dreamix.module').then((m) => m.DreamixModule)
      },
      {
        path: 'user',
        loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'vacation',
        loadChildren: () => import('./vacation/vacation.module').then((m) => m.VacationModule),
      },
      {
        path: 'requests/:id',
        component: RequestDetailsComponent,
        canActivate: [roleGuard('USER')],
      },
      {
        path: 'request/action/:action/:id/:hash',
        component: ActionRequestComponent,
        canActivate: [roleGuard('USER')],
      },
      {
        path: 'lead',
        data: { breadcrumb: 'Lead', link: false },
        canActivateChild: [roleGuard('LEAD')],
        children: [
          {
            path: 'export-hours',
            data: { breadcrumb: 'Export Logged Time', link: true },
            component: ClientHoursComponent,
          },
          {
            path: 'projects',
            data: { breadcrumb: 'BREADCRUMBS.PROJECTS', link: true },
            children: [
              {
                path: '',
                data: { breadcrumb: null, link: false, isLead: true },
                component: ProjectsComponent,
              },
              {
                path: 'update/:id',
                data: { breadcrumb: null, link: false, isLead: true },
                component: EditProjectComponent,
              },
            ],
          },
        ],
      },
    ],
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

export const routing = RouterModule.forRoot(routes, {
  useHash: true,

  // enables route params as inputs feature in v16
  bindToComponentInputs: true,
});
