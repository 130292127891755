import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DashboardWidgetData, WidgetData } from '../models/widgets.model';

@Injectable({
  providedIn: 'root',
})
export class WidgetsService {
  private urlBase: string = '/api/widget';

  constructor(private httpClient: HttpClient) {}

  public getAllWidgets() {
    return this.httpClient.get<DashboardWidgetData[]>(`${this.urlBase}`);
  }

  public getWidget(name: string) {
    return this.httpClient.get<WidgetData>(`${this.urlBase}/${name}`);
  }
}
