import { Component, Input } from '@angular/core';
import { WallOfFameData } from 'src/app/shared/models/widgets.model';

@Component({
  selector: 'app-wall-of-fame',
  templateUrl: './wall-of-fame.component.html',
  styleUrls: ['./wall-of-fame.component.scss'],
})
export class WallOfFameComponent {
  @Input() data: WallOfFameData[];
}
