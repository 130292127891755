<div id="breadcrumb" *ngIf="menuItems?.length">
  <a href="/"><mat-icon>home</mat-icon></a>

  <ng-template #crumb let-item="crumb">
    <mat-icon>keyboard_arrow_right</mat-icon>
    <a href="{{ item.url }}" *ngIf="item.url">{{ item.label | translate }}</a>
    <a *ngIf="!item.url">{{ item.label | translate }}</a>
  </ng-template>

  <ng-container *ngFor="let item of menuItems">
    <ng-container *ngTemplateOutlet="crumb; context: { crumb: item }"></ng-container>
  </ng-container>

  <ng-container *ngFor="let item of dynMenuItems">
    <ng-container *ngTemplateOutlet="crumb; context: { crumb: item }"></ng-container>
  </ng-container>
</div>
