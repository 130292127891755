<swiper-container *ngIf="filteredData?.length" #swiperRef init="false">
  <swiper-slide *ngFor="let nugget of filteredData">
    <div class="swiper-gutter">
      <app-weekly-nugget [nugget]="nugget"></app-weekly-nugget>
    </div>
  </swiper-slide>
</swiper-container>

<div *ngIf="!filteredData?.length" class="nugget-empty">
  <p>There are no events yet!</p>
</div>
