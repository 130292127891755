import {
  GoogleLoginProvider,
  GoogleSigninButtonModule,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { NgOptimizedImage } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, importProvidersFrom, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MaterialModule } from 'src/app/shared/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { environment } from 'src/environments/environment';

import { routing } from './app.routing';
import { AppComponent } from './app.component';
import { ActionRequestComponent } from './action-request/action-request.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { WallOfFameComponent } from './landing-page/wall-of-fame/wall-of-fame.component';
import { WeeklyNuggetsComponent } from './landing-page/weekly-nuggets/weekly-nuggets.component';
import { LoginComponent } from './login/login.component';
import { BreadcrumbComponent } from './nav-bar/breadcrumb/breadcrumb.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { NavLinksComponent } from './nav-bar/nav-links/nav-links.component';
import { SearchComponent } from './nav-bar/search/search.component';
import { UserNavLinksComponent } from './nav-bar/user-nav-links/user-nav-links.component';
import { RequestDetailsComponent } from './request-details/request-details.component';
import { UploadTravelDocumentsDialogComponent } from './travel-requests/upload-travel-documents-dialog/upload-travel-documents-dialog.component';
import { UserHelpComponent } from './user-help/user-help.component';
import { CertificatesDialogComponent } from 'src/app/user/my-settings/employee-certificates/certificates-dialog.component';

const googleLoginOptions = {
  oneTapEnabled: false,
  scopes: ['profile email https://www.googleapis.com/auth/calendar'],
};

@NgModule({ schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        ActionRequestComponent,
        AppComponent,
        BreadcrumbComponent,
        LandingPageComponent,
        LoginComponent,
        NavBarComponent,
        NavLinksComponent,
        RequestDetailsComponent,
        SearchComponent,
        UploadTravelDocumentsDialogComponent,
        UserHelpComponent,
        UserNavLinksComponent,
        WallOfFameComponent,
        WeeklyNuggetsComponent,
        CertificatesDialogComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        SocialLoginModule,
        routing,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        MaterialModule,
        SharedModule.forRoot(),
        TranslateModule,
        GoogleSigninButtonModule,
        NgOptimizedImage], providers: [
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(window.location.href.includes('dms.dreamix.eu')
                            ? '1026810753126-r6rp7mn8c0n50bg8j6bbvreir00ikt9t.apps.googleusercontent.com'
                            : '103728556371-7qq16vua2qrg14852bpe498hivrl5pac.apps.googleusercontent.com', googleLoginOptions),
                    },
                ],
                onError: (err) => {
                    console.error(err);
                },
            } as SocialAuthServiceConfig,
        },
        importProvidersFrom(TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', `/en.json`),
                deps: [HttpClient],
            },
        })),
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
