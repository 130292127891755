// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDffhIhHJYURynad1sj1h4vdPiki0PGgNk',
    authDomain: 'dreamix-management-system.firebaseapp.com',
    databaseURL: 'https://dreamix-management-system.firebaseio.com',
    projectId: 'dreamix-management-system',
    storageBucket: 'dreamix-management-system.appspot.com',
    messagingSenderId: '1026810753126',
    appId: '1:1026810753126:web:54de00ba6b09a126807da7',
    measurementId: 'G-9LW3PNDB2T',
  },
  google: {
    calendarApiKey: 'AIzaSyAJW-O7SoGLWoxh8Ka4btsL9FX9ejVrzZw',
    clientId: '103728556371-7qq16vua2qrg14852bpe498hivrl5pac.apps.googleusercontent.com',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
