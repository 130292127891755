import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  constructor(public router: Router) {}

  public filter: string;
  public search(filter: string): void {
    if (!filter) return;
    filter = filter.trim();
    if (!!filter) {
      this.filter = '';
      this.router.navigate(['dreamix/search-results/' + encodeURI(filter)]);
    }
  }
}
