<app-banner />
<div class="dashboard-header">
  <h1>
    <span>Welcome</span> onboard, <strong>{{ this.user.firstNameEn }}</strong>
  </h1>
  <div class="right">
    <nav>
      <ul>
        <li><a href="https://wish.dreamix.eu/login" target="_blank">Wish</a></li>
        <li><a href="https://give.dreamix.eu/login" target="_blank">Give</a></li>
        <li><a href="https://dreamix.mybrandvocate.com/login/" target="_blank">Shop</a></li>
        <li><a href="https://dreamix.eu/insights/category/technology/" target="_blank">Blog</a></li>
      </ul>
      <ul>
        <li>
          <a href="https://hybrid.officernd.com/community/dreamix/login" target="_blank"
            >Book a desk</a
          >
        </li>
      </ul>
    </nav>
    <button
      *ngIf="customizeDashboardVisible"
      mat-stroked-button
      color="primary"
      type="button"
      (click)="expandWidgetDashboard()"
    >
      <mat-icon>settings</mat-icon>
      {{ 'LANDING_PAGE.CUSTOMIZE_DASHBOARD' | translate }}
    </button>
  </div>
</div>
<div class="widget-list">
  <div class="widget" *ngIf="widgetList?.events">
    <h1 class="widget-title">Weekly <strong>nuggets</strong></h1>
    <app-weekly-nuggets [data]="widgetList?.events"></app-weekly-nuggets>
  </div>
  <div class="widget" *ngIf="widgetList?.wallOfFame">
    <h1 class="widget-title"><strong>Wall</strong> of fame</h1>
    <app-wall-of-fame [data]="widgetList?.wallOfFame" />
  </div>
</div>
