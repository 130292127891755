import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { LoginService } from './shared/services/login.service';
import { DOCUMENT, Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isUserLogIn: boolean = false;
  private destroy$ = new Subject<void>();

  constructor(
    public loginService: LoginService,
    public location: Location,
    public translate: TranslateService,
    @Inject(DOCUMENT) private _document: Document,
  ) {}

  public ngOnInit(): void {
    this.initiateTranslation(this.translate);
    const document = this._document;
    const loginService = this.loginService;
    const observer = new MutationObserver(function () {
      const iframe = document.getElementById('jsd-widget') as HTMLIFrameElement;
      if (iframe) {
        const iframe = document.getElementById('jsd-widget') as HTMLIFrameElement;
        iframe.style.visibility = loginService.isUserLogIn() ? '' : 'hidden';
        const innerDoc = iframe.contentDocument || iframe.contentWindow.document;
        innerDoc.body.onclick = (e) => {
          if (!loginService.isUserLogIn()) {
            e.stopImmediatePropagation();
          }
        };
        observer.disconnect();
      }
    });
    observer.observe(document, {
      attributes: false,
      childList: true,
      characterData: false,
      subtree: true,
    });

    this.loginService.isUserLogIn$.pipe(takeUntil(this.destroy$)).subscribe((isLoggedIn) => {
      this.isUserLogIn = isLoggedIn;
      const iframe = document.getElementById('jsd-widget');
      if (iframe) iframe.style.visibility = isLoggedIn ? '' : 'hidden';
    });
  }

  public isRequestAction(): boolean {
    return this.location.path().includes('/request/action/');
  }

  public isClientProject(): boolean {
    return this.location.path().includes('/client');
  }

  public isHelpPage(): boolean {
    return this.location.path().includes('/help');
  }

  initiateTranslation(translate: TranslateService) {
    translate.addLangs(['en']);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en/) ? browserLang : 'en');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
