import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NuggetEvent } from 'src/app/shared/models/nugget-event.model';
import { SwiperContainer } from 'swiper/element';

@Component({
  selector: 'app-weekly-nuggets',
  templateUrl: './weekly-nuggets.component.html',
  styleUrls: ['./weekly-nuggets.component.scss'],
})
export class WeeklyNuggetsComponent implements AfterViewInit, OnInit {
  @Input() data: NuggetEvent[];
  @ViewChild('swiperRef') swiperRef?: ElementRef<SwiperContainer>;

  filteredData: NuggetEvent[] = [];

  ngOnInit() {
    this.filteredData = this.data.filter((nugget) => nugget.img || nugget.context);
  }

  ngAfterViewInit() {
    if (!this.swiperRef) return;

    const options = {
      // since swiper is web component, we cannot apply styles outside of the element
      // we need to inject styles using swiper api
      injectStyles: [
        `
          .swiper-pagination-bullet {
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 0;
            background: rgba(0, 0, 0, 1);
          }
          .swiper-pagination-bullet-active {
            background: #ff004d;
          }
        `,
      ],
      pagination: {
        clickable: true,
      },
      autoplay: {
        delay: 3000,
        pauseOnMouseEnter: true,
      },
      loop: true,
    };

    Object.assign(this.swiperRef.nativeElement, options);

    this.swiperRef.nativeElement.initialize();
  }
}
