import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LoginService } from 'src/app/shared/services';

export const authGuard: CanActivateFn = (_, state) => {
  const loginService = inject(LoginService);
  const router = inject(Router);

  if (!loginService.isUserLogIn()) {
    router.navigate(['login'], { queryParams: { redirectTo: state.url } });
    return false;
  }

  return true;
};
