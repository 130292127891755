<app-login
  *ngIf="!(isUserLogIn || this.isRequestAction() || this.isClientProject() || this.isHelpPage())"
></app-login>

<div *ngIf="isUserLogIn || this.isRequestAction() || this.isClientProject() || this.isHelpPage()">
  <app-nav-bar
    *ngIf="!this.isRequestAction() && !this.isClientProject() && !this.isHelpPage()"
  ></app-nav-bar>
  <div id="main" class="main-layout">
    <router-outlet></router-outlet>
  </div>
</div>
